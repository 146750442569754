import axios from "axios";
import config from "../config/config";

// 현재 get에 parameter로 보내는 방식은 구현되지 않음.
export async function NSAxios(_url, _method, _data, _header, _flagBlob)
{
  if ( (_method+"").toLowerCase()=='post')
    _method = 'post';
  else
    _method = 'get';

  if ( !_header )
    _header = {'aidocent-rest-api-key': config.apiKey};

  const axiosConfig = {
    url: _url,
    method: _method,
    headers: _header,
    data: _data,
    baseURL: config.apiServerURL,
  }
  if ( _flagBlob )
    axios.responseType = 'blob';
  let ret = null;
  try
  {
    ret = await axios(axiosConfig)
  }
  catch(e)
  {
    console.log("[E] ", e.response);
    if ( e.response && e.response.status==500 )
      alert('잘못된 project API key 입니다.');
  };

  return ret

}