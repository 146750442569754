import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import DocentMain from './components/DocentMain';
import ReactGA from 'react-ga4';
import config from './config/config';
import Starter from './Starter';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


export function GAEvent(action, label)
{
  const sendObj = 
    {
      category: "user-action",
      action: action,
      // label: "your label", // optional
      // value: 99, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      // transport: "xhr", // optional, beacon/xhr/image
    }
  if ( label )
    sendObj.label = label;
  ReactGA.event( sendObj);
}

if ( config.env=='production')
{
  console.log = ()=>{};
  console.info = ()=>{};
  console.error = ()=>{};
}

function App() {
  let flagRun = false;

  return (
    <Routes>
      <Route path="/" element={<Starter />} />
      <Route path="/:projectKey" element={<Starter />} />
    </Routes>
  );
}

export default App;
