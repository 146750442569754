const _env = "prod";    // local, dev, prod

const configProduction = 
{
  // ############### PRODUCTION SECTION ###################
  env:'production',
  apiServerURL: 'https://aidocent-api.netstream.co.kr',
  apiServerURL: 'https://server.ai-docent.com',
  ga4MeasurementID: 'G-LLJL16X33W',

}

const configDevelopment =
{
  // ############### DEVELOPMENT SECTION ###################
  // production으로 갈 때는 이 섹션만 주석처리 하면 됨
  env:'development',
  apiServerURL: 'http://localhost:14000',
  ga4MeasurementID: 'G-',
  ga4MeasurementID: 'G-WB3LS4NEBE',   // event-anc

}

const _config =
{
  maxTimeRecord: 5,   // 녹음시 최대 5초까지


  // ############### COMMON SECTION ###################
  conversationID : null,
  apiKey: 'e4-sample',
  audioContent: '',

}

let config = null;

switch ( _env )
{
  case 'prod':
    config = { ..._config, ...configProduction };
    break;
  case 'dev':
  case 'local':
  default:
    config = { ..._config, ...configDevelopment };
    break;
}

console.log("Config : ", config);

export default config;