import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import DocentMain from './components/DocentMain';
import config from './config/config';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
import { GAEvent } from './App';

function Starter() {
  let flagRun = false;

  // ##########  parameter(project key) setting ##########
  let param = useParams();
  console.log("Param : ", param);
  if ( param && param.projectKey && param.projectKey.length>0 )
    config.apiKey = param.projectKey;

  useEffect( ()=>{
    if ( flagRun )
      return;
    // ##########  GA4 setting ##########
    ReactGA.initialize(config.ga4MeasurementID);
    // ReactGA.send("pageview");
    // ReactGA.send({ hitType:"pageview", page:`/${param}`, title: '' });
    console.log("GA Initialized : ", param);
    // GAEvent("projectKey", param);

    flagRun = true;
  }, [])


  return (
    <div className="App">
      <header className="App-header">
        
        {/* <!-- TITLE --> */}
        <div className='header'>
           AI DOCENT
        </div>
        {/* <!-- 텍스트 --> */}
        <div>
          인공지능에게 물어보세요 
        </div>
        <div className="center-box"> 
          <DocentMain />
        </div>
      </header>
    </div>
  );
}

export default Starter;
